import axios from "axios"
import {Toast} from "vant"
Toast.allowMultiple();
const instance=axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    timeout:60*1000
})
instance.interceptors.request.use(
    function (config) {
        return config
    },
    function (err) {
        Toast({
            icon:"clear",
            forbidClick:true,
            message:"网络错误\n请检查后重试",
            duration: 2000
        })
        return Promise.reject(err)
    }
)
instance.interceptors.response.use(
    function (res) {
        if (res.status==200) {
            return res.data
        }else{
            Toast({
                icon:"clear",
                forbidClick:true,
                message:res.message,
                duration: 2000
            })
            return Promise.reject(res.data) 
        }
    },
    function (err) {
        Toast({
            icon:"clear",
            forbidClick:true,
            message:"程序异常\n请联系管理员",
            duration: 2000
        })
        return Promise.reject(err)
    }
)
const http={
    get:(url,params)=>{
        
        return instance.get(url,{params})
    },
    post:(url,params)=>{
        return instance.post(url,params)
    },
    upload:(url,data,config)=>{
        return instance.post(url,data,config)
    }
}
export default http