import Vue from 'vue'
import 'amfe-flexible'
import App from './App.vue'
import '@/assets/styles/global.less'
import router from "./router"
import http from "./fetch"
Vue.prototype.$http = http
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
