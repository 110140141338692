import http from "./fetch"
import { Toast } from "vant"
//判断是否是微信浏览器
export function isWx() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
//base64转文件
export function convertBase64UrlToImgFile(urlData, fileName) {
    let arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });

}
//获取地址栏参数
export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}
//微信浏览器内获取openid
export function handleInWxGetOpenId() {
    const redirectUrl = window.location.href;
    const appid =
        window.location.origin == "https://pay.boluozaixian.com"
            ? "wxe6f2d53112685daf"
            : "wxe6f2d53112685daf";
    const code = getQueryVariable("code");
    const openid = sessionStorage.getItem("openid")
        ? JSON.parse(sessionStorage.getItem("openid"))
        : "";
    if (code && !openid) {
        http
            .get("/front/pay/auth/getOpenId", { code })
            .then((res) => {
                if (res.code == 1 && res.data) {
                    sessionStorage.setItem("openid", JSON.stringify(res.data));
                } else {
                    Toast({
                        icon: "clear",
                        forbidClick: true,
                        message: res.message,
                        duration: 2000,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } else if (!code && !openid) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
            redirectUrl
        )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    }
}