<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { isWx,handleInWxGetOpenId } from "./tool";
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    if (isWx()) {
      handleInWxGetOpenId()
    }
  },
  methods: {
    
  },
};
</script>

<style>
body {
  font-size: 16px;
}
#app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
