import Vue from "vue"
import VueRouter from "vue-router"
import {Toast} from "vant"
Vue.use(VueRouter)
const routes=[
{
    path:'/contract',
    name:"contract",
    meta:{
        title:"合同签约"
    },
    component:() => import('./pages/contract')

},
{
  path: '/contractDetails',
  name: 'contractDetails',
  meta: {
    title: '合同签约',
  },
  component: () => import('./pages/contractDetails'),
},
{
  path: '/newContractDetails',
  name: 'newContractDetails',
  meta: {
    title: '合同签约',
  },
  component: () => import('./pages/newContractDetails'),
},
{
  path: '/contractPayList',
  name: 'contractPayList',
  meta: {
    title: '支付列表',
  },
  component: () => import('./pages/contractPayList'),
},
{
    path:'/pay-list',
    name:"paylist",
    meta:{
        title:"支付列表"
    },
    component:() => import('./pages/pay-list')

},
{
    path:'/pay',
    name:"pay",
    meta:{
        title:"支付详情"
    },
    component:() => import('./pages/pay')

},
{
  path: '/payment',
  name: 'payment',
  meta: {
    title: '支付中心',
  },
  component: () => import('./pages/payment'),
},
{
    path:'*',
    meta:{
        title:"找不到页面"
    },
    component:()=>import('./pages/404')

}
]
const router=new VueRouter({
    mode:"history",
    routes
})
router.beforeEach((to,from,next)=>{
    const title=to.meta.title
    document.title='菠萝在线电子签约-'+title
    Toast.clear()
    next()
})
export default router